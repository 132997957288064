<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <validation-observer ref="addCourseFormvalidate">
      <b-form
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>

          <label class="title">Who we are:
          </label>
          <b-col
            cols="10"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                header image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside>
                  <b-img
                    ref="refPreviewEl"
                    :src="`https://api.lmitac.com/${form.who_we_are_image}`"
                    height="150"
                    width="200"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>

                  <b-card-text class="my-50" />
                  <div class="d-inline-block">
                    <b-form-group
                      label="header image"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image "
                        rules="required"
                      >
                        <b-form-file
                          ref="refInputEl"
                          v-model="form.who_we_are_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRenderer"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Tab Image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="form.who_we_are_alter"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>

          </b-col>
          <b-col cols="10">
            <b-form-group
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="required"
              >
                <QuillEditor
                  :editorRef="'whoWeAreEditor'"
                  v-model="form.who_we_are_value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>
        <label class="title">Our goal:
        </label>
        <b-row>

          <b-col
            cols="10"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                header image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside>
                  <b-img
                    ref="refPreviewE2"
                    :src="`https://api.lmitac.com/${form.our_goal_image}`"
                    height="150"
                    width="200"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>

                  <b-card-text class="my-50" />
                  <div class="d-inline-block">
                    <b-form-group
                      label="header image"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image "
                        rules="required"
                      >
                        <b-form-file
                          ref="refInputE2"
                          v-model="form.our_goal_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Tab Image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="form.our_goal_alter"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>

          </b-col>
          <b-col cols="10">
            <b-form-group
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="required"
              >
                <QuillEditor
                  :editorRef="'ourGoalEditor'"
                  v-model="form.our_goal_value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>
        <b-row>
          <label class="title">What we do1:
          </label>
          <b-col
            cols="10"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                header image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside>
                  <b-img
                    ref="refPreviewE3"
                    :src="`https://api.lmitac.com/${form.what_we_do1_image}`"
                    height="150"
                    width="200"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>

                  <b-card-text class="my-50" />
                  <div class="d-inline-block">
                    <b-form-group
                      label="header image"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image "
                        rules="required"
                      >
                        <b-form-file
                          ref="refInputE3"
                          v-model="form.what_we_do1_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab3"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Tab Image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="form.what_we_do1_alter"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>

          </b-col>
          <b-col cols="10">
            <b-form-group
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="required"
              >
                <QuillEditor
                  :editorRef="'whatWeDo1Editor'"
                  v-model="form.what_we_do1_value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>
        <b-row>
          <label class="title">What we do2:
          </label>
          <b-col
            cols="10"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                header image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside>
                  <b-img
                    ref="refPreviewE4"
                    :src="`https://api.lmitac.com/${form.what_we_do2_image}`"
                    height="150"
                    width="200"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>

                  <b-card-text class="my-50" />
                  <div class="d-inline-block">
                    <b-form-group
                      label="header image"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image "
                        rules="required"
                      >
                        <b-form-file
                          ref="refInputE4"
                          v-model="form.what_we_do2_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab4"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Tab Image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="form.what_we_do2_alter"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>

          </b-col>
          <b-col cols="10">
            <b-form-group
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="required"
              >
                <QuillEditor
                  :editorRef="'whatWeDo2Editor'"
                  v-model="form.what_we_do2_value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>
        <b-row>
          <label class="title">What we do3:
          </label>
          <b-col
            cols="10"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                header image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside>
                  <b-img
                    ref="refPreviewE5"
                    :src="`https://api.lmitac.com/${form.what_we_do3_image}`"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>

                  <b-card-text class="my-50" />
                  <div class="d-inline-block">
                    <b-form-group
                      label="header image"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image "
                        rules="required"
                      >
                        <b-form-file
                          ref="refInputE5"
                          v-model="form.what_we_do3_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab5"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Tab Image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="form.what_we_do3_alter"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>

          </b-col>
          <b-col cols="10">
            <b-form-group
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="required"
              >
                <QuillEditor
                  :editorRef="'whatWeDo3Editor'"
                  v-model="form.what_we_do3_value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>
        <b-row>
          <label class="title">What we do4:
          </label>
          <b-col
            cols="10"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                header image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside>
                  <b-img
                    ref="refPreviewE6"
                    :src="`https://api.lmitac.com/${form.what_we_do4_image}`"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>

                  <b-card-text class="my-50" />
                  <div class="d-inline-block">
                    <b-form-group
                      label="header image"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image "
                        rules="required"
                      >
                        <b-form-file
                          ref="refInputE6"
                          v-model="form.what_we_do4_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab6"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Tab Image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="form.what_we_do4_alter"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>

          </b-col>
          <b-col cols="10">
            <b-form-group
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="required"
              >
                <QuillEditor
                  :editorRef="'whatWeDo4Editor'"
                  v-model="form.what_we_do4_value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="10"
            class="mt-50"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import QuillEditor from '@core/components/editor/Editor'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive } from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'

export default {
  setup() {
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)
    const refInputE3 = ref(null)
    const refInputE4 = ref(null)
    const refPreviewE4 = ref(null)
    const refPreviewE3 = ref(null)
    const refInputE5 = ref(null)
    const refInputE6 = ref(null)
    const refPreviewE5 = ref(null)
    const refPreviewE6 = ref(null)
    const Tabimage = ref('media/svg/files/blank-image.svg')
    const categoriesList = ref([])
    const form = ref({
      who_we_are_value: '',
      who_we_are_id: '',
      who_we_are_alter: '',
      who_we_are_image: '',
      our_goal_value: '',
      our_goal_id: '',
      our_goal_image: '',
      our_goal_alter: '',
      what_we_do1_value: '',
      what_we_do1_id: '',
      what_we_do1_image: '',
      what_we_do1_alter: '',
      what_we_do2_value: '',
      what_we_do2_id: '',
      what_we_do2_image: '',
      what_we_do2_alter: '',
      what_we_do3_value: '',
      what_we_do3_id: '',
      what_we_do3_image: '',
      what_we_do3_alter: '',
      what_we_do4_value: '',
      what_we_do4_id: '',
      what_we_do4_image: '',
      what_we_do4_alter: '',

    })
    const addCourseForm = reactive(form)
    const data = ['who_we_are',
      'our_goal',
      'what_we_do1',
      'what_we_do2',
      'what_we_do3',
      'what_we_do4']
    const formData = new FormData()
    formData.append('data', JSON.stringify(data))
    store.dispatch('setting/GetSettingArray', formData).then(response => {
      form.value.who_we_are_value = response.data[0].value
      form.value.who_we_are_alter = response.data[0].alter_img
      form.value.who_we_are_id = response.data[0].id
      form.value.who_we_are_image = response.data[0].img
      form.value.our_goal_alter = response.data[1].alter_img
      form.value.our_goal_value = response.data[1].value
      form.value.our_goal_id = response.data[1].id
      form.value.our_goal_image = response.data[1].img
      form.value.what_we_do1_alter = response.data[2].alter_img
      form.value.what_we_do1_value = response.data[2].value
      form.value.what_we_do1_id = response.data[2].id
      form.value.what_we_do1_image = response.data[2].img
      form.value.what_we_do2_alter = response.data[3].alter_img
      form.value.what_we_do2_value = response.data[3].value
      form.value.what_we_do2_id = response.data[3].id
      form.value.what_we_do2_image = response.data[3].img
      form.value.what_we_do3_alter = response.data[4].alter_img
      form.value.what_we_do3_value = response.data[4].value
      form.value.what_we_do3_id = response.data[4].id
      form.value.what_we_do3_image = response.data[4].img
      form.value.what_we_do4_alter = response.data[5].alter_img
      form.value.what_we_do4_value = response.data[5].value
      form.value.what_we_do4_id = response.data[5].id
      form.value.what_we_do4_image = response.data[5].img
    })

    const addCourseFormvalidate = ref()

    const save = () => {
      const formData = new FormData()

      const data = [{
        name: 'who_we_are',
        value: form.value.who_we_are_value,
        alter_img: form.value.who_we_are_alter,
        image: form.value.who_we_are_image,
      },
      {
        name: 'our_goal',
        value: form.value.our_goal_value,
        alter_img: form.value.our_goal_alter,
        image: form.value.our_goal_image,
      }, {
        name: 'what_we_do1',
        value: form.value.what_we_do1_value,
        alter_img: form.value.what_we_do1_alter,
        image: form.value.what_we_do1_image,
      },
      {
        name: 'what_we_do2',
        value: form.value.what_we_do2_value,
        alter_img: form.value.what_we_do2_alter,
        image: form.value.what_we_do2_image,
      },
      {
        name: 'what_we_do3',
        value: form.value.what_we_do3_value,
        alter_img: form.value.what_we_do3_alter,
        image: form.value.what_we_do3_image,
      },
      {
        name: 'what_we_do4',
        value: form.value.what_we_do4_value,
        alter_img: form.value.what_we_do4_alter,
        image: form.value.what_we_do4_image,
      },

      ]

      formData.append('data', JSON.stringify(data))

      addCourseFormvalidate.value.validate().then(success => {
        if (success) {
          store
            .dispatch('setting/updateSettingArray', formData)
            .then(response => {
              Vue.swal({
                title: 'Updated',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }
    const { inputImageRendererTab3 } = useInputImageRenderer(
      refInputE3,
      base64 => {
        refPreviewE3.value.src = base64
      },
    )
    const { inputImageRendererTab4 } = useInputImageRenderer(
      refInputE4,
      base64 => {
        refPreviewE4.value.src = base64
      },
    )
    const { inputImageRendererTab5 } = useInputImageRenderer(
      refInputE5,
      base64 => {
        refPreviewE5.value.src = base64
      },
    )
    const { inputImageRendererTab6 } = useInputImageRenderer(
      refInputE6,
      base64 => {
        refPreviewE6.value.src = base64
      },
    )
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )
    function example_image_upload_handler(blobInfo, success, failure, progress) {
      let xhr; let
        formData
      const token = localStorage.getItem('token')

      xhr = new XMLHttpRequest()
      xhr.withCredentials = false
      xhr.open('POST', 'https://api.lmitac.com/api/upload_image_tiny_mce')

      xhr.upload.onprogress = function (e) {
        progress(e.loaded / e.total * 100)
      }
      xhr.setRequestHeader('Authorization', `Bearer ${token}`)
      xhr.onload = function () {
        let json

        if (xhr.status === 403) {
          failure(`HTTP Error: ${xhr.status}`, { remove: true })
          return
        }

        if (xhr.status < 200 || xhr.status >= 300) {
          failure(`HTTP Error: ${xhr.status}`)
          return
        }

        json = JSON.parse(xhr.responseText)

        if (!json || typeof json.location !== 'string') {
          failure(`Invalid JSON: ${xhr.responseText}`)
          return
        }

        success(json.location)
      }

      xhr.onerror = function () {
        failure(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`)
      }

      formData = new FormData()
      formData.append('image', blobInfo.blob(), blobInfo.filename())

      xhr.send(formData)
    }
    return {
      example_image_upload_handler,
      refInputEl,

      refPreviewEl,
      refInputE2,
      refPreviewE2,
      refInputE3,
      refPreviewE3,
      refInputE4,
      refPreviewE4,
      refInputE5,
      refPreviewE5,
      refInputE6,
      refPreviewE6,
      inputImageRendererTab,
      inputImageRendererTab6,
      inputImageRendererTab5,
      inputImageRendererTab4,
      inputImageRendererTab3,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      categoriesList,
      form,

      save,
      required,
      email,
    }
  },

  components: {
    BCard,
    BMedia,
    QuillEditor,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,

    BForm,
  
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style>
label.title {
    font-size: 25px;
}
.border.rounded.p-2 {
   margin: 1px !important;
}
.p-2{
  margin: 0px !important;
}
</style>
